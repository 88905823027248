import React from 'react';
import {Switch, withRouter} from 'react-router-dom';
import InvoiceListContainer from '../containers/InvoiceListContainer';
import InvoiceDetailsContainer from '../containers/InvoiceDetailsContainer';
import AddEnrolleeComponent from './AddEnrolleeComponent';
import PropsRoute from './utils/PropsRoute';
import NewInvoiceComponent from './NewInvoiceComponent';

const InvoiceComponent = ({match, user}) => (
    <Switch>
        <PropsRoute exact path={`${match.url}/:invoiceId`} component={InvoiceDetailsContainer} user={user}/>
        <PropsRoute exact path={`${match.url}/edit/add-enrollee`} component={AddEnrolleeComponent} user={user}/>
        <PropsRoute exact path={`${match.url}/edit/new-invoice`} component={NewInvoiceComponent} user={user}/>
        <PropsRoute path={match.url} component={InvoiceListContainer} user={user}/>
    </Switch>
);

export default withRouter(InvoiceComponent);