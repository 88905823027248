// FeatureFlagContext.js
import React, { createContext } from 'react';
import { isFeatureEnabled, getEnvValue } from '../components/utils/featureFlag';

export const FeatureFlagContext = createContext({});

export const FeatureFlagProvider = ({ children }) => {
    let hiddenPlanIds = getEnvValue('hidden_plan_ids') || [];

    if (hiddenPlanIds?.length) {
        hiddenPlanIds = hiddenPlanIds.includes(',')
            ? hiddenPlanIds.split(',').map((item) => +item)
            : [+hiddenPlanIds];
    }

    const featureFlags = {
        flags: {
            logoName: getEnvValue('logo_name'),
            languageOptionsEnabled: isFeatureEnabled('language_options'),
            domain: getEnvValue('domain'),
            companyName: getEnvValue('company_name'),
            hiddenPlanIds
        }
    };

    return (
        <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>
    );
};
