import React from 'react';
import { useFeatureFlags } from '../utils/FeatureFlagWrapper';
import LanguagueSelector from '../utils/LanguageSelector';

const FormHolder = (props) => {
    const getMessage = (() => {
        if (props.message === 'The type field is required.') {
            return props.language.reset_password.reset_password_page.error;
        } else if (props.message === 'Email Address or HMO ID is Invalid') {
            return props.language.reset_password.reset_password_page.error2;
        } else if (props.message === 'No account was found with that Email/HMO ID') {
            return props.language.reset_password.reset_password_page.error3;
        } else if (props.message === 'Invalid security question answer') {
            return props.language.reset_password.reset_password_page.notification.success
                .secret_question.error2;
        } else if (props.message === 'Expired Code. Please restart the process') {
            return props.language.reset_password.reset_password_page.notification.success
                .secret_question.error1;
        } else if (props.message === 'Use a different password') {
            return props.language.reset_password.reset_password_page.notification.success
                .new_password.error.one;
        } else if (props.message === 'An Error Occurred') {
            return props.language.login_error;
        } else if (props.message === 'The passwords you entered do not match.' || 'undefined') {
            return props.language.reset_password.reset_password_page.notification.success
                .new_password.error.two;
        } else {
            return ' ';
        }
    })();
    const { flags } = useFeatureFlags();
    const logoPath = `/img/${flags.logoName}`;

    return (
        <div className="dashboard" style={{ height: '100%', position: 'relative' }}>
            {flags.languageOptionsEnabled && (
                <div
                    style={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        width: 'max-content',
                        height: 'max-content',
                        padding: '20px 40px 0 0'
                    }}
                >
                    <LanguagueSelector />
                </div>
            )}
            <div className="login_form">
                <img src={logoPath} alt="Reliance Health's Logo" style={{ maxWidth: '20rem' }} />

                {props.isLogin && (
                    <div
                        className="dashboard-card__body-text"
                        dangerouslySetInnerHTML={{
                            __html: props.language.reset_password.login_page.text.one
                        }}
                    />
                )}

                {props.message && (
                    <div
                        style={{
                            padding: '10px',
                            backgroundColor: 'red',
                            color: 'white',
                            visibility: !props.isLoading && props.message ? 'visible' : 'hidden'
                        }}
                    >
                        {getMessage}
                    </div>
                )}

                {props.children}
            </div>
        </div>
    );
};

export const MessageHolder = (props) => {
    return (
        <div
            style={{
                display: 'grid',
                padding: '10px',
                margin: '0 auto',
                width: '90%',
                height: '50%',
                alignItems: 'center',
                backgroundColor: 'white',
                color: '#111'
            }}
        >
            <div>{props.children}</div>
        </div>
    );
};

export default FormHolder;
