import React from 'react';
import moment from 'moment';
import userService from '../services/userService';
import LoadingScreen from '../components/LoadingScreen';
import SelectExpiredReason from '../components/SelectExpiredReason';
import PlanResetNotice from '../components/PlanResetNotice';
import PlanSelectionInfo from '../components/PlanSelectionInfo';
import VariableBillingNotice from '../components/VariableBillingNotice';
import utilityService from '../services/utilityService';
import VariableBillingReviewEnrollees from '../components/VariableBillingReviewEnrollees';
import VariableBillingSelectPayment from '../components/VariableBillingSelectPayment';
import { ItemContextSwitcher } from '../components/ItemContextSwitcher';
import EnrolleeRenewalList from '../components/lists/EnrolleeRenewalList';



class InvoiceRenewalContainer extends React.Component {

  PAGESTATUS = {
    LOADING: "LOADING",
    EXPIRED_REASON: "EXPIRED_REASON",
    PLAN_RESET_NOTICE: "PLAN_RESET_NOTICE",
    PLAN_SELECTION_INFO: "PLAN_SELECTION_INFO",
    VARIABLE_BILLING_NOTICE: "VARIABLE_BILLING_NOTICE",
    VARIABLE_BILLING_SELECT_PAYMENT: "VARIABLE_BILLING_SELECT_PAYMENT",
    VARIABLE_BILLING_REVIEW_ENROLLEES: "VARIABLE_BILLING_REVIEW_ENROLLEES",
    ORDER_SUMMARY: "ORDER_SUMMARY"
  }

  PLAN_TAGLINES = {
    ALEXANDRITE: "1.8M in health coverage and access to over 800 hospitals.",
    RED_BERYL: "1.2M in health coverage and access to over 600 hospitals."
  }

  PAYMENT_FREQUENCIES = [
    {id: 3, name: "monthly"},
    {id: 7, name: "quarterly"},
    {id: 4, name: "yearly"}
  ]

  constructor(props) {
    super(props);
    this.state = {
      status: this.PAGESTATUS.EXPIRED_REASON,
      renewalReasons: [],
      plans: [],
      enrollees: [],
      paymentFrequency: this.PAYMENT_FREQUENCIES.find(freq => freq.id === props.invoice.payment_frequency_id) ?? this.PAYMENT_FREQUENCIES[0],
      variableBilling: {},
      variableBillingPaymentSchedule: "once",
      payVariableBill: props.invoice.has_variable_billing ?? false,
    }
  }

  componentDidMount() {
    const { invoice } = this.props;

    utilityService.getPlans("all")
      .then((plans) => {
        this.setState({plans});
      });
    
    userService.getRenewalReasons()
      .then(renewalReasons => {
        this.setState({ renewalReasons });
      });

    userService.getInvoiceEnrollees(invoice.reference_code)
      .then(enrollees => {
        if (typeof enrollees === "object") {
          enrollees = Object.values(enrollees);
        }
        enrollees.map(enrollee => {
          enrollee.isSelected = true;
          return enrollee;
        });
        this.setState({ enrollees });
      });
    
    if (invoice.has_variable_billing) {
      userService.getVariableBillingInfo(invoice.reference_code)
        .then(variableBilling => {
          this.setState({ variableBilling })
        });
    }
  }

  payInvoice = () => {
    if (this.state.payVariableBill) {
      this.payVariableBill();
      return;
    }

    if(this.props.invoice.active_status_id === 4) {
      this.props.onRenew();
      return;
    }

    this.buyNewPlansForSelectedEnrollees();
  };

  buyNewPlansForSelectedEnrollees = () => {
    let plans = [];

    this.state.enrollees.forEach(enrollee => {
      if(enrollee.isSelected) {
        let added = false;
        plans = plans.map(plan => {
          if (plan.id === enrollee.product.id) {
            plan.quantity += 1; 
            plan.users.push({id: enrollee.user_id});
            added = true;
          }
          return plan;
        })
        if (!added) {
          plans.push({id: enrollee.product.id, quantity: 1, users: [{id: enrollee.user_id}]});
        }
      }
    })

    this.props.onBuyNewPlan({      
      cart: [
        ...plans.map(plan => {
          return {product_id: plan.id, no_of_enrollees: plan.quantity, users: plan.users, extensions: []}
        })
      ],
      meta: {
        payment_frequency: this.state.paymentFrequency.name,
        for: "others"
      }
    });
  }

  payVariableBill = () => {
    const enrolleeIds = this.state.enrollees.filter(enrollee => enrollee.isSelected).reduce((ids, enrollee) => ([...ids, enrollee.user_id]), []);
    const one_time = this.state.variableBillingPaymentSchedule === "once";

    this.props.onVariableBilling( enrolleeIds, one_time);
  }

  sendReasonAndContinue = (renewalReason) => {
    userService.sendRenewalReason(this.props.invoice.reference_code, renewalReason);
    let status = this.props.invoice.has_variable_billing ?
      this.PAGESTATUS.VARIABLE_BILLING_NOTICE : this.PAGESTATUS.PLAN_RESET_NOTICE;
    this.setState({ status, renewalReason });
  };

  toggleEnrollee = (enrolleeId) => {
    if (this.props.invoice.active_status_id === 4) {
      return
    }
    let enrollees = this.state.enrollees.map(enrollee => {
      if(enrollee.user_id === enrolleeId) {
        enrollee.isSelected = !enrollee.isSelected;
      }
      return enrollee;
    });
    if (enrollees.filter(enrollee => enrollee.isSelected).length) {
      this.setState({enrollees});
    }
  }

  changeEnrolleeProduct = (enrolleeId, productIndex) => {
    let product = this.state.plans[productIndex];
    let enrollees = this.state.enrollees.map(enrollee => {
      if(enrollee.user_id === enrolleeId) {
        enrollee.product = {
          id: product.id,
          name: product.name,
          plan_type: product.type,
          price: product.price[this.state.paymentFrequency.name]
        };
      }
      return enrollee;
    });
    this.setState({enrollees});
  }

  setPaymentFrequency = (paymentFrequency) => {
    this.setState({paymentFrequency});

    let enrollees = this.state.enrollees.map(enrollee => {
      let product = this.state.plans.find(plan => plan.id === enrollee.product.id);
      enrollee.product = {
        id: product.id,
        name: product.name,
        plan_type: product.type,
        price: product.price[paymentFrequency.name]
      };
      return enrollee;
    });
    this.setState({enrollees});
  }

  setVariableBillingPaymentSchedule = (event) => {
    this.setState({
      variableBillingPaymentSchedule: event.currentTarget.value
    })
  }

  calculateVariableBill = () => {
    const selectedEnrollees = this.state.enrollees.filter(enrollee => enrollee.isSelected);
    const schedule = this.state.variableBillingPaymentSchedule === "once" ? "onetime_fee" : "monthly_fee";
    return selectedEnrollees.reduce((total, enrollee) => {
      const enrolleeBill = this.state.variableBilling.breakdown[enrollee.user_id]?.[schedule] ?? 0;
      return total + enrolleeBill;
    }, 0);
  }

  calculateProductTotal = () => (
    this.state.enrollees
      .filter(enrollee => enrollee.isSelected)
      .reduce((total, enrollee) => (total + parseFloat(enrollee.product.price)), 0)
  )

  calculateAmountPayable = () => {
    if (this.props.invoice.has_variable_billing && this.state.payVariableBill) {
      return this.calculateVariableBill();
    }
    return this.calculateProductTotal();
  }

  renderEnrolleeList = () => (
    <>
      {this.props.invoice.active_status_id === 5 && !this.state.payVariableBill &&
        <ItemContextSwitcher
          items={this.PAYMENT_FREQUENCIES}
          selectedItem={this.state.paymentFrequency}
          onItemSelected={this.setPaymentFrequency}
        />
      }
      <EnrolleeRenewalList 
        enrollees={this.state.enrollees}
        isNewPurchase={this.props.invoice.active_status_id === 5 && !this.state.payVariableBill}
        onEnrolleeToggle={this.toggleEnrollee}
        onEnrolleeChangePlan={this.changeEnrolleeProduct}
        plans={this.state.plans}
      />
    </>
  )

  renderLoading = () => {
    return <LoadingScreen />
  };

  renderExpiredReason = () => (
    <SelectExpiredReason
      invoice={this.props.invoice}
      duration={moment().diff(moment(this.props.invoice.end_date), 'd')} 
      reasons={this.state.renewalReasons} 
      onSelect={this.sendReasonAndContinue} 
    />
  )

  renderPlanResetNotice = () => (
    <PlanResetNotice onNext={
        () => {
          this.setState({
            status: this.PAGESTATUS.PLAN_SELECTION_INFO,
            payVariableBill: false
          })
        }
      } 
      onBack={
        () => { this.setState({ status: this.PAGESTATUS.EXPIRED_REASON }) }
      }
    />
  )

  renderPlanSelectionInfo = () => (
    <PlanSelectionInfo 
      enrolleeList={this.renderEnrolleeList} 
      total={this.calculateAmountPayable()}
      onContinue={this.payInvoice}
      onBack={
        () => {
          let status = this.props.invoice.has_variable_billing ?
            this.PAGESTATUS.VARIABLE_BILLING_NOTICE : this.PAGESTATUS.PLAN_RESET_NOTICE;
          this.setState({ status });
        }
      }
    />
  );

  renderVariableBillingNotice = () => (
    <VariableBillingNotice 
      onReactivate={() => {
        this.setState({
          status: this.PAGESTATUS.VARIABLE_BILLING_REVIEW_ENROLLEES,
          payVariableBill: true
        })
      }}
      onRestart={() => {
      this.setState({
        status: this.PAGESTATUS.PLAN_SELECTION_INFO,
        payVariableBill: false
      })
    }} />
  )

  renderVariableBillingReviewEnrollees = () => (
    <VariableBillingReviewEnrollees
      enrolleeList={this.renderEnrolleeList}
      onContinue={() => this.setState({status: this.PAGESTATUS.VARIABLE_BILLING_SELECT_PAYMENT})}
      onBack={() => this.setState({status: this.PAGESTATUS.VARIABLE_BILLING_NOTICE})}
    />
  );

  renderVariableBillingSelectPayment = () => (
    <VariableBillingSelectPayment
      variableBill={this.calculateVariableBill()}
      schedule={this.state.variableBillingPaymentSchedule}
      onSelect={this.setVariableBillingPaymentSchedule}
      total={this.calculateAmountPayable()}
      onPayInvoice={this.payInvoice}
      onBack={() => { this.setState({ status: this.PAGESTATUS.VARIABLE_BILLING_REVIEW_ENROLLEES }) }}
    />
  )

  render() {

    const { status } = this.state;

    switch (status) {
      case this.PAGESTATUS.EXPIRED_REASON:
        return this.renderExpiredReason();

      case this.PAGESTATUS.PLAN_RESET_NOTICE:
        return this.renderPlanResetNotice();

      case this.PAGESTATUS.PLAN_SELECTION_INFO:
        return this.renderPlanSelectionInfo();

      case this.PAGESTATUS.VARIABLE_BILLING_NOTICE:
        return this.renderVariableBillingNotice();

      case this.PAGESTATUS.VARIABLE_BILLING_REVIEW_ENROLLEES:
        return this.renderVariableBillingReviewEnrollees();

      case this.PAGESTATUS.VARIABLE_BILLING_SELECT_PAYMENT:
        return this.renderVariableBillingSelectPayment();

      case this.PAGESTATUS.LOADING:
      default:
        return this.renderLoading();
    }
  }

}

export default InvoiceRenewalContainer