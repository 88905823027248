import React from 'react';
import PropTypes from 'prop-types';
import {reduxForm, Field} from 'redux-form';


let LoginForm = (props) => {

    const {handleSubmit, pristine, submitting, language } = props;

    return (
        <form action="" className="dashboard-form form" onSubmit={handleSubmit}>
          <span>
              <Field component="input" className="form-input" type="text" name="email" placeholder={language.reset_password.login_page.placeholder.email} required/>
          </span>
          <span>
              <Field component="input" className="form-input" type="password" name="password" placeholder={language.reset_password.login_page.placeholder.password} required/>
          </span>

          <input type="submit" className="dashboard__primary-btn" disabled={pristine || submitting } value={language.reset_password.login_page.button} />
        </form>
    )
};

LoginForm.propTypes = {
    onSubmit: PropTypes.func
};

LoginForm = reduxForm({
    form:'loginForm',
    destroyOnUnmount:false,
    initialValues: {remember_me: true}
})(LoginForm);

export default LoginForm;