import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { getLanguageText } from '../../actions/language';
import { languages, getLanguage } from './Constants';


export default function LanguagueSelector(){
    const { language } = useSelector(state => state.language);
    const [selectedLanguage, setSelectedLanguage] = useState(language)
    const dispatch = useDispatch();
    const lang = localStorage.getItem('languageOption') || selectedLanguage
    const handleChangeSelector= (e) => {
        setSelectedLanguage(e.target.value)
        localStorage.setItem("languageOption", e.target.value)
        dispatch(getLanguageText(getLanguage(e.target.value)))
    }

    useEffect(() => {
        document.getElementById('root').dir = lang === 'ENGLISH' ? 'ltr' : 'rtl';
    }, [lang])

    return (
        <div className='language_selector'>
            <select name="language" id="language" value={lang} onChange={handleChangeSelector} style={{border:0, outline:'none'}}>
                {Object.values(languages).map((i, index) => {
                    // eslint-disable-next-line
                    return <option key={index} value={i.value} >&#127758;&nbsp; {i.label}&nbsp;&nbsp;</option>
                })}
            </select>
        </div>
    )
};