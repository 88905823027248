import React from 'react';
import PropTypes from 'prop-types';

class Modal extends React.Component{

    render(){
      if(this.props.open){
        return (
          <div className="modal" style={{display: "block"}}>
            <div className="modal-content">
              <span className="modal-close" onClick={this.props.onClose}>&times;</span>
              <div>
                <h1 className="dashboard-card__title-text modal-header">{this.props.title}</h1>
                {this.props.children}
              </div>
            </div>
          </div>
        )
      }else {
        return null;
      }
    }

}

Modal.propTypes = {
    title: PropTypes.string,
    children: PropTypes.object,
    onClose: PropTypes.func,
    open:PropTypes.bool.isRequired
};

export default Modal;
