import React from 'react';
import * as _ from 'lodash';
import axios from 'axios';
import utilityService from '../../services/utilityService';
import LoadingScreen from '../LoadingScreen';
import ExtensionCartSummary from './ExtensionCartSummary';
import ExtensionDetail from './ExtensionDetail';

class ExtensionSelectorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeExtension: "",
      addedExtensions: [],
      activeTab: "list",
      activeView: "extensions",
      extensions: [],
      isLoading: true,
      providers: []
    }
  }

  componentDidMount() {
    this.setLoading(true);

    axios.all([utilityService.getPlans("extension")])
      .then(response => {        
        let extensions = response[0].filter(ext => {
          const extProduct = ext.name.substring(ext.name.indexOf("(")+1, ext.name.indexOf(")")-2).toLowerCase(); //extension product                    
          return (
            this.props.planName.toLowerCase().indexOf(extProduct.toLowerCase()) !== -1) //only include extensions on the same level as the user's product
            && this.props.exclude.indexOf(ext.id) === -1 //exclude user's product
        });
        this.setState({
          extensions,
          providers: response[1],
          activeExtension: extensions.length === 0 ? "" : extensions[0].id,
          activeTab: "detail"
        });
        this.setLoading(false);
      })
      .catch(e => {
        console.log(e);
      });
  }

  viewExtension = (ext) => {
    this.setState({
      activeExtension: ext,
      activeTab: "detail"
    })
  };

  addToExtensionCart = (extObj) => {
    let {addedExtensions} = this.state;
    if (addedExtensions.filter((ext) => ext.id === extObj.id).length === 0) {
      addedExtensions.push(extObj);
      this.setState({
        addedExtensions
      });
      this.backToList();
    }
  };

  removeFromExtensionCart = (extObj) => {
    const newExtensions = this.state.addedExtensions;
    _.remove(newExtensions, (ext) => ext.id === extObj.id);
    this.setState({
      addedExtensions: newExtensions
    })
  };

  backToList = () => {
    this.setState({
      activeTab: "list"
    })
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading
    })
  };

  toggleCheckOutView = () => {
    this.setState({
      activeView: this.state.activeView === "extensions" ? "checkout" : "extensions"
    })
  };

  onCheckOut = (useNewCard, frequency) => {
    this.setLoading(true);
    const json = [];
    this.state.addedExtensions.map(ext => {
      return json.push({
        product_id: ext.id,
        users: [{id: this.props.userId}],
        no_of_enrollees: 1
      })
    })
    this.props.onComplete({useNewCard,cart:json, frequency: frequency});
  };

  render() {

    const {isLoading, extensions, addedExtensions, activeExtension, activeTab, activeView} = this.state;

    if (isLoading) {
      return (
        <div className="extension-selector-wrapper" style={{height: "150px"}}>
          <LoadingScreen/>
        </div>
      )
    }

    if (activeView === "checkout") {
      return (
        <div className="extension-selector-wrapper no-padding">
          <div className="button-header no-padding"
               style={{alignItems: "center", marginBottom: "1rem", justifyContent: "flex-start"}}>
            <button className="dashboard__secondary-btn"  style={{border: "none"}} onClick={this.toggleCheckOutView}>
              <i className="fa fa-chevron-left"/>
              {' '}
              Back
            </button>
          </div>
          <ExtensionCartSummary extensions={addedExtensions} onPayNow={this.onCheckOut}/>
        </div>
      )
    }

    if(extensions.length === 0){
      return (
        <div className="extension-selector-wrapper">
          <div style={{display: "flex", alignItems: "center", justifyContent: "center", minHeight: "150px"}}>
            <h3 style={{padding: "3rem 2rem", color: "#d6d6d6", fontSize: "3rem"}}>
              There are currently no extensions you can purchase for this enrollee.
            </h3>
          </div>
        </div>
      )
    }

    return (
      <div className="extension-selector-wrapper">
        <div style={{alignItems: "center", marginBottom: "1rem", display: "flex", justifyContent: "flex-end"}}>
          <p className="dashboard-card__body-text" style={{width: "100%", textAlign: "end", margin: "0", paddingRight: "1rem"}}> 
            {addedExtensions.length} Benefit(s) Added
          </p>
          {addedExtensions.length > 0 &&
          <button className="dashboard-tab__button" style={{margin: "0", background: "#058605", color: "white"}} onClick={this.toggleCheckOutView}>
            PROCEED
          </button>
          }
        </div>

        <div className="dashboard-btn-section dashboard-tab"
            style={{flexWrap: "wrap", padding: "0"}}>
            {extensions.map((ext) => {
              return (
                <button 
                  key={ext.id} 
                  className={`dashboard-tab__button ${activeExtension === ext.id ? "dashboard-tab__button-active" : ""}`}
                  onClick={() => this.viewExtension(ext.id)}>
                    {ext.name}
                </button>
              )
            })}
        </div>

        {activeExtension &&
          <ExtensionDetail
            isActive={activeTab === "detail"}
            onAdd={this.addToExtensionCart}
            onRemove={this.removeFromExtensionCart}
            addedExtensions={addedExtensions}
            meta={{planExpires: this.props.planExpires, planName: this.props.planName}}
            extension={getExtensionFromId(extensions, activeExtension)}/>
        }
      </div>
    )
  }

}

const getExtensionFromId = (extArray, _ext) => {
  return extArray.filter((ext) => {
    return ext.id === _ext
  })[0];
};

export default ExtensionSelectorComponent;