import React from 'react';
import { useFeatureFlags } from '../utils/FeatureFlagWrapper';

const PreAuthFormHolder = (props) => {
    const { flags } = useFeatureFlags();
    const logoPath = `/img/${flags.logoName}`;
    const companyName = `${flags.companyName}`;
    return (
        <div className="dashboard" style={{ height: '100%' }}>
            <div className="login_form">
                <img src={logoPath} alt={`${companyName}'s Logo`} style={{ maxWidth: '20rem' }} />

                {props.isLogin && (
                    <p className="dashboard-card__body-text">
                        To access to all your dashboards,
                        <br />
                        Login with your {companyName} account{' '}
                    </p>
                )}

                {props.message && (
                    <div
                        style={{
                            padding: '10px',
                            backgroundColor: 'red',
                            color: 'white',
                            visibility: !props.isLoading && props.message ? 'visible' : 'hidden'
                        }}
                    >
                        {props.message}
                    </div>
                )}

                {props.children}
            </div>
        </div>
    );
};

export default PreAuthFormHolder;
