import React from 'react';
import PropTypes from 'prop-types';
import CheckoutList from "../lists/CheckoutList";
import Modal from "../boxes/Modal";

const OrderSummaryModal = ({plans, paymentFreq, onCheckout, onClose, open}) => {
  return (
    <Modal title="Order Summary" onClose={onClose} open={open}>
      <CheckoutList plans={plans} paymentFreq={paymentFreq} onCheckout={onCheckout}/>
    </Modal>
  )
};


OrderSummaryModal.propTypes = {
  plans: PropTypes.array,
  paymentFreq: PropTypes.string,
  onCheckout: PropTypes.func,
  onClose: PropTypes.func
};


export default OrderSummaryModal;